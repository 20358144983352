import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class TicketSubmissionService {
  constructor(private http: HttpClient) {}

  submitTicket(ticketForm) {
    const formData = new FormData();
    if (ticketForm.offenseNumber.charAt(7) == ticketForm.offenseNumber.charAt(7).toLowerCase()) {
      ticketForm.offenseNumber = ticketForm.offenseNumber.toUpperCase();
    }
    formData.append("firstName", ticketForm.firstName);
    formData.append("lastName", ticketForm.lastName);
    formData.append("email", ticketForm.email);
    formData.append("phone", ticketForm.phone);
    formData.append("offenseNumber", ticketForm.offenseNumber);
    formData.append("ticketImage", ticketForm.ticketImage);
    formData.append("driversLicense", ticketForm.driversLicense);
    formData.append("iconCode", ticketForm.iconCode);
    return this.http.post("/submitForm", formData);
  }
}
