import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule}  from '@angular/material/tooltip';
import { FileUploadComponent } from './subcomponents/app-file-upload/app-file-upload.components';
import { HttpClientModule } from '@angular/common/http';
import { TicketSubmissionService } from './ticket-submission.service';



@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    HttpClientModule
  ],
  providers: [TicketSubmissionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
