import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { TicketSubmissionService } from "./ticket-submission.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  loading = false;
  formSubmittedSuccessfully = false;
  ticketSubmissionForm: FormGroup = null;
  showModal = false;
  displayModalSpinner = false;
  submissionErrorOccured = false;
  successfulSubmissionMessage = null;

  constructor(
    private formBuilder: FormBuilder,
    private ticketSubmissionService: TicketSubmissionService
  ) {}

  ngOnInit() {
    this.ticketSubmissionForm = this.formBuilder.group({
      firstName: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.minLength(1),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{10}"),
      ]),
      offenseNumber: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8),
        Validators.pattern("[0-9]{7}[A-z]"),
      ]),
      ticketImage: new FormControl(null, Validators.required),
      driversLicense: new FormControl(null, Validators.required),
      iconCode: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{4}"),
      ]),
    });
  }

  // Form Getters
  get firstName() {
    return this.ticketSubmissionForm.get("firstName");
  }

  get lastName() {
    return this.ticketSubmissionForm.get("lastName");
  }

  get email() {
    return this.ticketSubmissionForm.get("email");
  }

  get phone() {
    return this.ticketSubmissionForm.get("phone");
  }

  get offenseNumber() {
    return this.ticketSubmissionForm.get("offenseNumber");
  }

  get ticketImage() {
    return this.ticketSubmissionForm.get("ticketImage");
  }

  get driversLicense() {
    return this.ticketSubmissionForm.get("driversLicense");
  }

  get iconCode() {
    return this.ticketSubmissionForm.get("iconCode");
  }

  // Methods

  clearForm() {
    window.location.reload();
  }

  onSubmit(formValues) {
    this.showModal = true;
    this.displayModalSpinner = true;
    this.ticketSubmissionService.submitTicket(formValues.value).subscribe(
      (res: any) => {
        this.displayModalSpinner = false;
        this.successfulSubmissionMessage = `Thank you ${formValues.value.firstName}, your ticket: ${formValues.value.offenseNumber} has been submitted`;
      },
      (error) => {
        this.displayModalSpinner = false;
        this.submissionErrorOccured = true;
        console.log(error);
      }
    );
  }
}
