<div>
    <mat-label class="mb-1">
        <strong>
            Upload an image of your {{ imageToUpload }}:
        </strong>
    </mat-label>
    <br/><br/>
    <div>
        <input style="background-color: #e3e3e3; padding: 15px;" class="file-input" type="file" (change)="onSelectFile($event)">
    </div>
    <div *ngIf="url">
        <br/>
        <div><img width="250" [src]="url"></div>
    </div>
</div>
