import { Component, Input, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: './app-file-upload.template.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true
          }
    ]
  })
  export class FileUploadComponent implements ControlValueAccessor {
    @Input() imageToUpload: string;
    @Output() showImagePreview: EventEmitter<any> = new EventEmitter<any>();
    onChange: Function;
    file: File | null = null;
    url = null;
  
    @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
      const file = event && event.item(0);
      this.onChange(file);
      this.file = file;
    }
  
    constructor( private host: ElementRef<HTMLInputElement> ) {
    }
  
    writeValue( value: null ) {
      // clear file input
      this.host.nativeElement.value = '';
      this.file = null;
    }
  
    registerOnChange( fn: Function ) {
      this.onChange = fn;
    }
  
    registerOnTouched( fn: Function ) {
    }

    onSelectFile(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.url = reader.result; 
      }
    }
  
  }
