<div
  class="main-content">
  <div
    class="col-12 mb-4 px-0">
    <div
      class="d-flex">
      <div
      class="back-block pr-2 pr-lg-3">
      <div>
        <a
          class="pr-4 arrow-anchor"
          href="http://www.legendslegal.com/">
        <i class="arrow left"></i>
      </a>
      </div>
    </div>
    <div class="header-block pt-3 pb-2 pl-3 pr-4 w-100">
      <div
        class="header-block-title">
        LEGENDS LEGAL
      </div>
      <div class="pt-2 pb-2 header-block-subtitle">
        Enter Traffic Ticket Information
      </div>
    </div>
    </div>
  </div>
  <div class="col-12">
    <form
      *ngIf="ticketSubmissionForm !== null"
      [formGroup]="ticketSubmissionForm"
      (ngSubmit)="onSubmit(ticketSubmissionForm)">
  
      <!-- First Name -->
      <div
        class="col-12 col-lg-4 mb-2">
        <mat-label>
          First Name
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your first name" formControlName="firstName">
        </mat-form-field>
        <mat-error
          *ngIf="firstName.touched && firstName.invalid">
          First name is required
        </mat-error>
      </div>
  
      <!-- Last Name -->
      <div
      class="col-12 col-lg-4 mb-2">
        <mat-label>
          Last Name
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your last name" formControlName="lastName">
        </mat-form-field>
        <mat-error
          *ngIf="lastName.touched && lastName.invalid">
          Last name is required
        </mat-error>
      </div>
  
      <!-- Email -->
      <div
        class="col-12 col-lg-4 mb-2">
        <mat-label>
          Email
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your email" formControlName="email">
        </mat-form-field>
        <mat-error
          *ngIf="email.touched && email.invalid">
          Email is required and must be a proper email
        </mat-error>
      </div>
      
  
      <!-- Phone -->
      <div
        class="col-12 col-lg-4 mb-2">
        <mat-label>
          Phone Number
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your phone number" formControlName="phone">
        </mat-form-field>
        <mat-error
          *ngIf="phone.touched && phone.invalid">
          Phone number is required and must be a proper phone number
        </mat-error>
      </div>
  
      <!-- Icon Code-->
      <div
        class="col-12 col-lg-4 mb-2">
        <mat-label>
          Icon Code
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your icon code" formControlName="iconCode">
        </mat-form-field>
        <br/>
        <mat-hint
          align="end">
          Must be 4 numbers.
          <br/>
          It is located on the ticket as shown below:
          <br/>
          <br/>
          <img width="250" src="../assets/icon-code.png">
        </mat-hint>
        <mat-error
          *ngIf="iconCode.touched && iconCode.invalid">
          Icon code is required and must be four numbers
        </mat-error>
        <br/>
        <br/>
      </div>
  
      <!-- Offense Number -->
      <div
        class="col-12 col-lg-4 mb-2">
        <mat-label>
          Offense Number
        </mat-label>
        <br/>
        <mat-form-field>
          <input matInput placeholder="Enter your offense number" formControlName="offenseNumber">
        </mat-form-field>
        <br/>
        <mat-hint
          align="end">
          Must contain 7 numbers followed by a letter.
          <br/>
          It is located on the ticket as shown below:
          <br/>
          <br/>
          <img width="250" src="../assets/offense-number.png">
        </mat-hint>
        <mat-error
          *ngIf="offenseNumber.touched && offenseNumber.invalid">
          Offense number is required and must be 7 numbers followed by an uppercased letter
        </mat-error>
        <br/>
      </div>
  
      <br/>
      <!-- Ticket Image -->
      <div
        class="col-12 col-lg-4 mb-2">
        <app-file-upload
          [imageToUpload]="'ticket'"
          formControlName="ticketImage">
        </app-file-upload>
        <br/>
        <div>
  
        </div>
        <mat-hint
          align="end">
          This is a mandatory field and must be a valid image format
        </mat-hint>
        <mat-error
          *ngIf="ticketImage.touched && ticketImage.invalid">
          Ticket image is required
        </mat-error>
      </div>
  
      <br/>
      <!-- Driver's Licenswe Image -->
      <div
        class="col-12 mb-5">
        <app-file-upload
          [imageToUpload]="'driver\'s license'"
          formControlName="driversLicense">
        </app-file-upload>
        <br/>
        <mat-hint
          align="end">
          This is a mandatory field and must be a valid image format
        </mat-hint>
        <mat-error
          *ngIf="driversLicense.touched && driversLicense.invalid">
          Driver's license image is required
        </mat-error>
      </div>
  
  
      <div
        class="col-12 col-lg-4 d-flex align-items-center justify-content-start">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          [disabled]="!ticketSubmissionForm.valid">
          Submit
        </button>
      </div>
    </form>
  </div>
  <div
    class="spacer">
    &nbsp;
  </div>
</div>
<div *ngIf="showModal"
  class="custom-modal-overlay">
  <div
    class="col-12 px-0 mx-0 d-flex align-items-center justify-content-center">
    <div
      *ngIf="!displayModalSpinner"
      class="col-12 col-lg-4 actions-container centered">
      <div
        class="header-block-subtitle"
        *ngIf="submissionErrorOccured">
        <div
          style="color: white;">
          Oops, something went wrong ...
        </div>
        <br/><br/>
      </div>
      <div
        class="header-block-subtitle"
        *ngIf="!submissionErrorOccured && successfulSubmissionMessage !== null">
        <div
          style="color: white; line-height: 1.5">
          {{ successfulSubmissionMessage }}
        </div>
        <br/><br/>
      </div>
      <div
        class="btn submit-another-ticket-button"
        (click)="clearForm()">
        <span
          *ngIf="submissionErrorOccured">
          Retry Submitting Ticket
        </span>
        <span
          *ngIf="!submissionErrorOccured">
          Submit Another Ticket
        </span>
      </div>
      <br/><br/>
      <a
        class="btn go-home-button"
        href="http://www.legendslegal.com/">
        Done
      </a>
    </div>
    <div
      *ngIf="displayModalSpinner"
      class="loader centered">
    </div>
  </div>
</div>

